import moment from 'moment'
import { appStore, router } from "@/main";
import DOMPurify from "dompurify";

//Sentry
import * as Sentry from "@sentry/vue";
import { Breadcrumbs } from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

export default {
    install(Vue, options) {


        Vue.prototype.$getFloatValue = function (val) {

            val = parseFloat(val);
            val = val.toFixed(2);

           if(isNaN(val)) return 0;

            return val;

        }

        Vue.prototype.$dateFormat = function (value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        }

      Vue.prototype.$dateFormatDots = function (value) {
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY')
        }
      }

        Vue.prototype.$dateTimeFormat = function (value) {
            if (value) {
                return moment(String(value)).format('DD.MM.YYYY HH:mm')
            }
        }

			Vue.prototype.$dateTimeFormatWithDash = function (value) {
				if (value) {
					return moment(String(value)).format('YYYY-MM-DD-HH-mm')
				}
			}

        Vue.prototype.$getCookie = function(name){

            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        Vue.prototype.$setCookie = function(name, value, options = {}){
            //$setCookie('key', 'value', {'expires': 10*24*60*60*1000});

            options = {
                path: '/',
								SameSite: 'None',
								Secure: true,
                ...options
            };

            if (options.expires && options.expires.toUTCString) {
                options.expires = options.expires.toUTCString();
            }

            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

            for (let optionKey in options) {
                updatedCookie += "; " + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                  updatedCookie += "=" + optionValue;
                }
            }

            document.cookie = updatedCookie;
        }

        Vue.prototype.$deleteCookie = function(name){
            this.$setCookie(name, "", {
                'max-age': -1
            })
        }

        Vue.prototype.$forceFileDownload = function(response, title){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

				Vue.prototype.$consoleLogHandler = function(data){
					let enabled = false

					const getSettings = function() {
						if (
							appStore.getters?.get_instance_settings.length &&
							appStore.getters?.get_instance_settings?.find((s) => s.key === 'console_log_enabled')
						) {
							enabled = appStore.getters?.get_instance_settings
								?.find((s) => s.key === 'console_log_enabled').value === '1' || false
						}
					}

					if(!appStore.getters?.get_instance_settings.length) {
						setTimeout(() => {
							getSettings();
							if (enabled) console.log(data)
						}, 2000)
					} else {
						getSettings();
						if (enabled) console.log(data)
					}
        }

			Vue.prototype.$sanitizedContent = function(data){
				return DOMPurify.sanitize(data);
			}

			Vue.prototype.$sanitizedHrefHttps = function(url){
				if(process.env.VUE_APP_NODE_ENV !== 'production'){
					const pattern = /^(https?:\/\/)/;

					return pattern.test(url) ? url : '#';
				} else return url
			}

			Vue.prototype.$initSentry = function(
				enabled = '0',
				logLevels = 'error',
				replaysEnabled = '0'
			) {

				// Check if Sentry is already initialized to avoid multiple instances
				if (Sentry?.getCurrentHub()?.getClient()) {
					return;
				}

				//Connection with Sentry Logger
				Sentry.init({
					Vue,
					dsn: "https://b5012e61c3d4f87d0de180b86eb97085@sentry.kwizbot.io/2",
					logErrors: true,
					attachProps: true,
					attachStacktrace: true,
					integrations: [
						new Sentry.BrowserTracing({
							routingInstrumentation: Sentry.vueRouterInstrumentation(router),
							tracingOrigins: ["localhost", process.env.VUE_APP_CONSTRUCTOR_URL.split('://')[1], /^\//],
						}),
						new Breadcrumbs({
							console: true,
							fetch: true,
							sentry: true,
							xhr: true,
						}),
						new Sentry.Replay({
							maskAllText: true,
							blockAllMedia: true,
						}),
						new CaptureConsole({
							levels: logLevels.split('_'),
						}),
					],
					tracingOptions: {
						trackComponents: true,
					},
					environment: process.env.VUE_APP_NODE_ENV,
					release: process.env.VUE_APP_VERSION,

					// Set tracesSampleRate to 1.0 to capture 100%
					// of transactions for performance monitoring.
					// We recommend adjusting this value in production
					tracesSampleRate: process.env.VUE_APP_NODE_ENV === 'production' ? 0.3 : 1,
					// This sets the sample rate to be 10%. You may want this to be 100% while
					// in development and sample at a lower rate in production
					replaysSessionSampleRate: replaysEnabled === '1' ? 0.1 : 0,
					// If the entire session is not sampled, use the below sample rate to sample
					// sessions when an error occurs.
					replaysOnErrorSampleRate: replaysEnabled === '1' ? 1.0 : 0,

					debug: process.env.VUE_APP_NODE_ENV === 'development' && enabled === '1',
					enabled: enabled === '1',
				});

				Sentry.setTag("project_url", process.env.VUE_APP_CONSTRUCTOR_URL.split('://')[1]);
				//end Connection with Sentry Logger
			}

			Vue.prototype.$getInstanceSettings = function(key, withParseValue = false){
				if(key) {
					if(withParseValue) {
						try {
							return JSON.parse(appStore.getters?.get_instance_settings?.find((s) => s.key === key).value)
						} catch(error) {
							return appStore.getters?.get_instance_settings?.find((s) => s.key === key)
						}

					} else {
						return appStore.getters?.get_instance_settings?.find((s) => s.key === key)
					}
				} else {
					return appStore.getters?.get_instance_settings
				}
			}

			Vue.prototype.$getComparator = function(operator){
				const operators = {
					"gt": (a, b) => a > b,
					"lt": (a, b) => a < b,
					"eq": (a, b) => a === b,
					"gte": (a, b) => a >= b,
					"lte": (a, b) => a <= b
				};

				return operators[operator];
			}
    }
}
