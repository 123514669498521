import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/OperatorPanel/layout/Layout.vue';

export default [
  {
    path: '/operator_panel',
    component: LAYOUT.base,
    name: 'OperatorPanel',
    redirect: '/operator_panel/chats',
    children: [
      {
        path: 'chats',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'OperatorPanelChats',
            component: () => import(/* webpackChunkName: 'op-chats' */ '@/modules/OperatorPanel/views/chats/ChatsTab.vue'),
          },
        ],
      },
      {
        path: 'chats-search',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'OperatorPanelChatsSearch',
            component: () => import(/* webpackChunkName: 'op-chats' */ '@/modules/OperatorPanel/views/chats/ChatsSearchTab.vue'),
          },
        ],
      },
			{
				path: 'table-view',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'OperatorPanelTableView',
						component: () => import(/* webpackChunkName: 'op-chats' */ '@/modules/OperatorPanel/views/tableView/TableView.vue'),
					},
				],
			},
    ],
  },
];
