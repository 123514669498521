<template>
    <v-dialog
      v-model="modal"
      :width="selected_macro ? '700' : '400'"
      scrollable
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          color="primaryLighten"
        >
          <v-icon>mdi-flash</v-icon>
        </v-btn>
      </template>

      <v-card>
<!--        <v-card-title>-->
<!--          {{$t('modules.dialog.texts.interface.sections.macro.modal_title')}}-->
<!--          <InfoTooltip-->
<!--            btnClass="ml-2"-->
<!--          >-->
<!--            <template v-slot:content>-->
<!--              <small>-->
<!--                {{$t('modules.dialog.texts.interface.sections.macro.title_info')}}-->
<!--                - <a :href="$t('documentation.documentation_call_node_notion_link')" target="_blank">-->
<!--                {{$t('modules.dialog.texts.common.documentation')}}-->
<!--              </a>-->
<!--              </small>-->
<!--            </template>-->
<!--          </InfoTooltip>-->
<!--        </v-card-title>-->
        <v-card-text class="pt-4">
          <v-autocomplete
            class="pt-1"
            :label="$t('modules.dialog.texts.interface.sections.macro.select_macro')"
            v-model="selected_macro"
            :items="macros"
            item-text="name"
            outlined
            background-color="primaryBackground"
            clearable
            return-object
            hide-details
          >
            <template v-slot:no-data>
              <div class="pa-3">
                {{$t('modules.dialog.texts.interface.sections.macro.select_macro_empty')}}
              </div>
            </template>
          </v-autocomplete>

          <v-sheet
            v-if="selected_macro"
            class="pa-4 mt-4 mb-3"
            outlined
            width="100%"
          >
            <div class="font-weight-bold mb-2">
              <v-icon color="primary" left>mdi-flash</v-icon>
              {{selected_macro.name}}
            </div>

            <div class="text-caption gray--text mb-2">
              {{selected_macro.macro_data.description}}
            </div>

            <template v-for="(item, index) in selected_macro.macro_data.params">
              <v-text-field
                :key="index"
                outlined
                :label="item.label"
                v-model="item.value"
                class="mb-2"
                dense
              />
            </template>
          </v-sheet>
        </v-card-text>

        <v-card-actions v-if="selected_macro">
          <v-btn
            @click="modal = !modal"
            class="mr-3 text-none"
            color="primary"
            outlined
          >
            {{$t('modules.dialog.texts.interface.sections.macro.cancel')}}
          </v-btn>
          <v-btn
            @click="runMacro('test')"
            color="primary"
            elevation="0"
            class="text-none"
          >
            {{$t('modules.dialog.texts.interface.sections.macro.run_macro')}}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {mapGetters} from "vuex";
// import InfoTooltip from "@/components/Modules/Extra/Tooltips/InfoTooltip.vue";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CustomDataMacroRun',
  components: {
    // InfoTooltip
  },

  props: {
    macros_params: {
      type: Object,
      default: () => {},
    },
    item_obj: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    modal: false,
    selected_macro: null,
    macros: [],

    widget_room_id: null,
  }),

  computed:  {
    ...mapGetters([
      'opActiveChatRoom',
      'engine_settings',
      'opChatRoomClient',
      'get_instance_settings',
    ]),
  },

  mounted: async function() {

  },

  methods: {
    async getMacros() {
      let token = Vue.$cookies.get('token_c');
      let data;
      await this.$store.dispatch('updateAjaxDialog', [true, true]);

      await axios
        .get(
          process.env.VUE_APP_CONSTRUCTOR_URL + '/kw/operator_panel/macros/list/' + this.macros_params?.bot_id,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            }
          },
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [
              false,
              false,
            ]);
            if (response.data && response.data.data) {
              data = response.data.data;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [
              err,
              this.$t('common.error'),
              this.$t('common.error_get'),
            ]);
          }
        );
      return data;

    },

    async runMacro() {
      this.widget_room_id = uuidv4().replace(/-/g, '');


      if(typeof kw_event !== 'function') {
        await this.initCodeChatWidget()
      } else {
        // eslint-disable-next-line
        await kw_event('kwreinitwidget', {
          bot_id: this.macros_params.bot_id,
          user_id: this.widget_room_id,
          params_from_site: {
            node_id: this.selected_macro.node_id,
            ...this.item_obj,
            macro_data_params: this.selected_macro?.macro_data?.params || null,
          }
        })
      }


      setTimeout(() => {
        // eslint-disable-next-line
        kw_event('openchat', 1)

        setTimeout(() => {
          // eslint-disable-next-line
          kw_event('kwsetparamsfromsite', { params_from_site: {}})
        }, 1000)
      }, 1500)

      this.modal = false
      this.selected_macro = null

      // await axios
      //   .get(
      //     `${this.engine_settings.active_url}/kw/api/call_node/${this.macros_params.bot_id}/${this.selected_macro?.node_id}/widget/${this.widget_room_id}`,
      //   )
      //   .then(
      //     (response) => {
      //       this.$store.dispatch('updateAjaxDialog', [
      //         false,
      //         false,
      //       ]);
      //       if (response.data && response.data.data) {
      //         this.$store.dispatch('updateAjaxDialog', [
      //           true,
      //           false,
      //           this.$t('common.success'),
      //           this.$t('modules.dialog.texts.interface.sections.macro.run_success'),
      //           false,
      //           {type: 'success'}
      //         ]);
      //
      //         this.initCodeChatWidget()
      //       }
      //     },
      //     (err) => {
      //       this.$store.dispatch('updateAjaxDialogError', [
      //         err,
      //         this.$t('common.error'),
      //         this.$t('modules.dialog.texts.interface.sections.macro.run_error'),
      //       ]);
      //     }
      //   ).finally(() => {
      //     this.modal = false
      //     this.selected_macro = null
      //   });
    },


    async initCodeChatWidget() {

      let kw_init_params = {
        language_code: "uk",
        bot_id: this.macros_params.bot_id,
        user_id: this.widget_room_id,
        launcher: {
          type: "image",
          staticImg: {
            src: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
            fit: "unset",
          },
        },

        titleImageUrl: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
        botAvatarImageUrl: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
        title: "Запуск процесу",
        btn_restart_enabled: true,
        launcherIsHiddenState: true,
        params_from_site: {
          node_id: this.selected_macro.node_id,
          ...this.item_obj,
          macro_data_params: this.selected_macro?.macro_data?.params || null,
        },
      }

      // get widget js url
      let widget_url = this.get_instance_settings.find((s) => s.key === 'operator_widget_url')?.value || ''
      widget_url = `${widget_url}kwjs.js`

      // enable Widget 2.0
      const operator_widget_2_0_enabled = this.get_instance_settings.find((s) => s.key === 'operator_widget_2_0_enabled')?.value
      if(operator_widget_2_0_enabled) {
        widget_url = 'https://widget.kwizbot.io/kwjs_v2.js'
        kw_init_params['v2_value'] = operator_widget_2_0_enabled
      }
      // end get widget js url

      // set additional params from settings
      let widget_init_params = this.get_instance_settings.find((s) => s.key === 'widget_init_params_marco_run')?.value
      if(widget_init_params) {
        try{
          widget_init_params = JSON.parse(widget_init_params)
          kw_init_params = {...kw_init_params, ...widget_init_params }
        } catch(e) {
          console.log(e, 'error parse widget_init_params_marco_run')
        }
      }
      // end set additional params from settings

      await (function (k, w, i, z, b, o, t) {
        k["KwizbotWidget"] = b;
        (k[b] =
          k[b] ||
          function () {
            w.head.insertAdjacentHTML(
              "beforeend",
              '<style type="text/css"></style>'
            );
            (k[b].q = k[b].q || []).push(arguments);
          }),
          (k[b].l = 1 * new Date());
        (o = w.createElement(i)), (t = w.getElementsByTagName(i)[0]);
        o.async = 1;
        o.src = z;
        t.parentNode.insertBefore(o, t);
      })(
        window,
        document,
        "script",
        widget_url,
        "kw"
      );

      try {
        // eslint-disable-next-line
        await kw("init", kw_init_params);

      } catch (e) {
        console.log(e, 'initCodeChatWidget')
      }

    },
  },

  watch: {
    modal: async function(val) {
      if (
        val &&
        this.macros_params?.bot_id
      ) {
        await this.getMacros().then(response => {
          this.macros = response?.sections || []
        })
      }

      if(!val) {
        this.selected_macro = null
      }
    }
  }
}
</script>
